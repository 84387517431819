<template>
  <Navbar :user="user" />
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <span class="sub-title">Step 1 of 3</span>
        <h3>Assign Courses to People</h3>
        <p>It's ok if you can't think of a good title now. You can change it later.</p>
      </div>
      <div class="contact-form">
        <form id="contactForm" @submit.prevent="assignCourse">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Choose Sharing Purpose</label>
                  <select  v-model="form.assigment_purpose" id="" class="form-control">
                      <option value="">Choose Sharing Purpose</option>
                      <option value="1" >For Interview</option>
                      <option value="2" >For Employees</option>
                      <option value="3" >For Learning</option>
                  </select>
                <div v-if="errors.assigment_purpose" class="text-small text-danger text-start">{{ errors.assigment_purpose[0] }}</div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 nt-1" v-for="(email, counter) in form.emails" v-bind:key="counter">
              <div class="form-group text-start">
                <div class="row">
                  <div class="col-md-8">
                    <label for="duration">Email {{counter+1}}:</label>
                  </div>
                  <div class="col-md-4">
                    <span @click="deleteEmail(counter)" class="btn text-danger">x</span>
                  </div>
                </div>
                <input type="text" class="form-control" v-model="email.email" required>
                <div v-if="errors.emails" class="text-small text-danger text-start">{{ errors.emails[0] }}</div>
              </div>
            </div>
            <div class="col-lg-10 col-md-10">
              <button @click="addEmail" class="btn">Add another Email</button>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Description</label>
               <textarea v-model="form.description" class="form-control"></textarea>
                <div v-if="errors.description" class="text-small text-danger text-start">{{ errors.description[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Assign the course</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
  components: { Navbar,Loading },
  data(){
    return {
      isLoading: true,
      submitting: false,
      token: localStorage.getItem('user_data'),
      form:{
        assigment_purpose:'', 
        emails:[
          {
            email:''
          }
        ], 
        description:'', course_id: this.$route.params.id
      },
      user: {},
      course: {},
      categories: {},
      sub_categories: {},
      subjects: {},
      errors: {}
    }
  },
  methods:{
    addEmail(){
      this.form.emails.push({
        email: ''
      })
    },
    deleteEmail(counter){
      this.form.emails.splice(counter,1);
    },
    assignCourse(){
      this.submitting = true
      this.value = 'Please Wait...'
      axios.post('https://apitraining.vipawaworks.com/api/course/assign_course_by_emails',this.form).then(response =>{
       this.course = response.data
        this.alerts = true
        this.$router.push({ name: 'TrainerCourses'})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Assign Course - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
    this.getUser()
  }
};
</script>

<style>
</style>
